import { useWebviewStore } from '../pinia/modules/webview';
import { useConfigStore } from '../pinia/modules/config';
import { getProductDataFromHTML } from '../utils/product.js';

const menuSelector = '#webview-menu';

export const initWebview = () => {
  const webviewStore = useWebviewStore();
  const configStore = useConfigStore();

  if (webviewStore.isWebview) {
    window.goBack = () => {
      const goBackIcon = document.getElementById('webview-back');
      if (goBackIcon) {
        goBackIcon.click();
      }
    };

    window.refreshState = () => {
      location.reload();
      window.scrollTo(0, 0);
    };

    document
      .getElementById('webview-menu-open-btn')
      ?.addEventListener('click', function () {
        document
          .getElementById('header-block')
          ?.classList.add('wv-menu-is-open');

        setTimeout(() => {
          //scroll menu back to top
          document.getElementById('webview-menu').scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }, 0);

        document
          .querySelector('.webview-search-block .aa-Form .aa-Input')
          ?.focus();
        document.getElementById('webview-search-input')?.focus();
        configStore.lockBodyScroll(menuSelector);
      });

    document
      .getElementById('webview-menu-close-btn')
      ?.addEventListener('click', function () {
        document.querySelector('.aa-ClearButton')?.click();
        document.querySelector('.js-header-search-clear')?.click();
        document
          .getElementById('header-block')
          ?.classList.remove('wv-menu-is-open');

        configStore.allowBodyScroll();
      });

    // TODO: Element with this ID does not exist
    document
      .getElementById('webview-authenticate')
      ?.addEventListener('click', function () {
        webviewStore.authenticate();
      });

    document
      .getElementById('webview-back')
      ?.addEventListener('click', function () {
        webviewStore.goBack();
      });

    document
      .querySelector('.js-webview-openQA')
      ?.addEventListener('click', function (event) {
        openNativeView(this, event, 'openQA');
      });

    document
      .querySelector('.js-webview-openGallery')
      ?.addEventListener('click', function (event) {
        openNativeView(this, event, 'openPhotoGallery');
      });

    const openNativeView = (that, event, fn) => {
      that.parentElement
        ?.querySelectorAll('.active')
        .forEach((el) => el.classList.remove('active'));

      const { legacyId, productName } = getProductDataFromHTML();

      if (legacyId && productName) {
        webviewStore[fn](legacyId, productName);
      }
    };
  }
};
